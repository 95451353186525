import React from 'react';

const NameDisplay = () => {
  return (
    <div className="name-display">
      <h1>Eng. Abdulldh mohammed Alotaibi</h1>
    </div>
  );
};

export default NameDisplay;
